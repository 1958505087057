/* This does the big font thingy idk men */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');


.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* might need this later
.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}


.logo {
  height: 1em;
  margin-left: 0.5rem;
  filter: invert(12%) sepia(97%) saturate(7344%) hue-rotate(12deg) brightness(100%) contrast(125%);
}
*/

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

.red {
  color: #F72C25;
  font: inherit;
  font-weight: 600;
}

.intro {
  font-weight: 400;
  font-size: 50px;
}

.name {
  color: #6154EE;
  font: inherit;
}


/* This does the big button thingy animation

Honestly I don't know what i'm doing here but it works so imma just leave it

TODO: cleanup code and fix */


.main_button {
  align-items: center;
  max-width: 650px;
  --color: #FFD700;
  --hover: #FFD700;
  font: 1em 'Montserrat', sans-serif;
  font-weight: 600;
  color: var(--color);
  transition: 0.3s;

  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding: 1em 2em;
}
.main_button {
  box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);
}
.main_button:hover, .main_button:focus {
  border-color: var(--hover);
  color: #0E0E10;
  box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
}


/* Text styling for portfolio section 

TODO: Fix space between title and description
*/


.paragraph {
  font: 1em 'Montserrat', sans-serif;
  max-width: 650px;
  padding-bottom: 1vh;
  font-weight: 600;
  text-align: center
}

.link {
  color: #6154EE;
  font-weight: 600;
  /* Underline */
  text-decoration: underline;
}

/* On hover make link blue and a little bigger with a transition time of 0.1s*/
.link:hover {
  color: #FFD700;
  transition: 0.1s;
}

